
.pet-activation-container {
  padding: 1em;
}

.petactivation-label-container {
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.petactivation-label-petdata {
  font-size: 1.5rem;
  margin-top: 1em;
  margin-bottom: 1em;
}

.pet-activation-container > p {
  text-align: center;
}

.petactivation-input-tag {
  margin-top: 0 !important;
  padding: 1rem 2rem !important;
  border: 1px solid black;
  border-radius: 5px;
}

.petactivation-drop-down {
  background-color: white;
  border: 1px solid black;
  padding: 1rem 2rem !important;
  border-radius: 5px !important;
}

.petactivation-gender-div {
  padding: 1em;
}

.petactivation-btn-grp {
  display: flex;
  gap: 1em;
}

.petActivation-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}

.petActivation-left-wrapper {
  position: relative;
}

.petA tivation-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #e6e5e3;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}

.petActivation-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}

.dashboard-verifyOtp-button {
  font-family: "prompt", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: #ffc107 !important;
  letter-spacing: 1px !important;
  padding: 8px 16px !important;
  border: none !important;
  outline: none !important;
  border-radius: 15px !important;
  color: white !important;
  box-shadow: 0px 2px 5px 0px #888888 !important;
}

.dashboard-verifyOtp-button:hover {
  background-color: #ebb105 !important;
}


.dashboard-otp-buttons {
  box-shadow: 0px 2px 5px 0px #888888;
  font-family: "prompt", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  letter-spacing: 1px;
  padding: 8px 16px;
  border: none;
  outline: none;
  border-radius: 15px;
  color: black;
}

.dashboard-otp-buttons:hover {
  background-color: #fff;
}

.dashboard-input__field__dialogs {
  padding: 0.375em 0.75em;
  border: 1px solid black;
  border-radius: 15px;
}

.dashboard-activateTag-popup {
  font-family: "prompt", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}
.close-button {
  position: absolute !important;
  top: 0rem !important; /* Position at the top */
  right: 0.2rem !important; /* Position at the right corner */
  z-index: 10 !important; /* Ensure it stays above other elements */
  background-color: transparent !important; /* Optional for a clean look */
  border: none !important;
  cursor: pointer !important;
  color: #555 !important;
  font-size: 1.5rem !important;
  transition: color 0.3s ease !important;
}

.close-button:hover {
  color: #000;
}

.MuiDialogContent-root {
  padding: 0 !important;
}
.small__popup {
  margin: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

.small__popup > h3 {
  margin-bottom: 0rem !important;
}

.small_pop_btn {
  /* padding: 0.5em 1em; */
  margin: 0rem 0rem 0.5rem 0rem;
  padding: 1rem 1rem 1rem 1rem;
  border: none;
  border-radius: 15px;
  background-color: #ffc107;
  font-size: 1.25rem;
  /* color: white; */
  box-shadow: 0px 2px 5px 0px #888888;
}

.petActivation-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.petActivation-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.petActivation-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.petActivation-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.petActivation-sidebar-menu-ul-hide {
  display: none;
}

.petActivation-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}

.MuiFormControlLabel-label {
  font-size: 14px !important;
  color: #b4b4b4;
}

.body-login {
  width: 80%;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  margin-top: 15px;
}
.petparentlinks {
  padding-left: 2rem;
  /* margin-top: 2rem; */
}
.image-upload {
  width: 120px;
  height: 120px;
  margin: auto !important;
}

#choose-profile-imageActi {
  display: none;
}
.add_img {
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 1px;
  color: black;
}
.fa-plus {
  color: gray;
  font-size: 14px;
  cursor: pointer;
}

#profile-image-uploadActi {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .dashboard__blacksidenav {
  width: 19rem;
  background-color:  #e7e4e4;
}
.tag_inside_btn {
  height: 2rem;
  width: 7rem;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}
.profile_name {
  padding-left: 2rem;
  color: black;
  width: 100%;
  text-decoration: none;
  list-style: none;
  display: flex;
  flex-direction: left;
  align-items: center;
  height: 2.3rem;
  margin-top: 2rem;
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
}

.profile_name:hover {
  cursor: pointer;
  background-color:  rgb(251 249 249) ;
  text-decoration: none;
}
.tag_inside_btn > span {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 3rem;
}
.tag1_text {
  color: black;
  font-size: 14px;
  border: none;
  outline: none;
  font-weight: 500;
}
.my-tags {
  padding-left: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: left;
  align-items: center;
  border: none;
}
.my-tags:hover {
  cursor: pointer;
  background-color:  rgb(251 249 249) ;
}
.my-tags1 {
  padding-left: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: left;
  align-items: center;
}
.my-tags1:hover {
  cursor: pointer;
  background-color:  rgb(251 249 249) ;
} */
.dashboard__blacksidenav {
  width: 23rem;
  background-color: #e7e4e4;
}
.tag_inside_btn {
  height: 3rem;
  width: 7rem;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #e7e4e4;
}
.tag_inside_btn:hover {
  background-color: rgb(251 249 249);
}
.profile_name {
  padding-left: 2rem;
  color: black;
  width: 100%;
  text-decoration: none;
  list-style: none;
  display: flex;
  flex-direction: left;
  align-items: center;
  height: 2.3rem;
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
  margin-top: -0.4rem;
}

.profile_name:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
  text-decoration: none;
}
.tag_inside_btn > span {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 3rem;
}

.tag1_text {
  font-size: 17px;
  border: none;
  outline: none;
}
.my-tags {
  padding-left: 2rem;
}
.my-tags1 {
  padding-left: 2rem;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: left;
  align-items: center;
}
.my-tags1:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
}
input {
  padding: 0px;
}

select {
  padding: 0px;
}

.mat-raised-button:disabled {
  background-color: #fcbc1b;
}

.image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
}
.button-image {
  background-color: transparent !important;
  color: white !important;
  border: none;
  outline: none;
}
.button-image:focus {
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  outline: none !important;
}

.fa-edit {
  margin-top: 48px;
  color: white;
}

.plus-icon {
  margin-top: 0px;
  color: #a1a1a1;
}

.image-upload:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.leftside-navigation {
  list-style: none;
  margin: auto;
  margin-left: -30px;
}

#uploadPreviewActi {
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  border: none;
  outline: none;
}

#uploadPreview {
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  display: none;
  border: none;
  outline: none;
}

.left-side {
  border-radius: 15px 0 0 15px;
  background-color: rgb(255, 234, 177);
}

.row-underline {
  border-bottom: 5px solid #fcbc1b;
}

.input-pet-details {
  border: none;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-1 {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 440px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-2 {
  border: none;
  outline: none;
  border-bottom: 2px solid #a1a1a1;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
  background-color: rgb(255, 234, 177);
}

.input-pet-details-dropdown {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 13px;
  padding: 0px;
}

.label-petdata {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "Lato", sans-serif;
}

.label-petdata-1 {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "Lato", sans-serif;
}

p strong {
  font-size: 18px;
}

.color-gray {
  color: #b4b4b4;
}

::ng-deep .mat-radio-label-content {
  font-weight: 550 !important;
}

.edit-parent input:focus {
  border: none !important;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4 !important;
  box-shadow: none !important;
  outline-width: 0 !important;
}

.edit-parent input:focus,
select:focus,
button:focus {
  outline: none !important;
}

input[readonly] {
  background-color: white;
}

.submit-login {
  width: 100%;
  height: 35px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}

.file {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #fcbc1b;
  border-radius: 5px;
  width: 100px;
  height: 30px;
  font-size: 13px;
}

.gender-male {
  font-weight: none;
  font-size: 13px;
}

.gender-female {
  font-weight: none;
  font-size: 13px;
}

.multiple-images {
  transition: transform 0.3s;
}

.multiple-images:hover {
  transform: scale(2.5);
}

@media only screen and (width: 1024px) {
  .leftside-navigation li {
    margin-right: 5px !important;
  }
}

@media only screen and (max-width: 990px) {
  .marginheight {
    margin-top: 0px !important;
  }
  .dashboard__blacksidenav {
    height: 100% !important;
  }
  .petparentlinks {
    margin-top: -30px !important;
    margin-left: 1px !important;
  }
  .body-login {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 850px) {
  .body-login {
    width: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .label-petdata {
    text-align: center;
  }
  .label-petdata-1 {
    text-align: center;
  }

  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  .leftside-buttons {
    margin-left: -50px !important;
  }
  .marginheight {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 576px) {
  .below-gender-move {
    margin-top: 0px;
  }
  .body-login {
    border-radius: 0px;
  }
  .left-side {
    border-radius: 0px 0 0 0px;
  }
  .leftside-buttons {
    margin-left: 0px !important;
  }
  .petparentlinks {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }
}

@media only screen and (max-width: 360px) {
  .input-pet-details {
    width: 200px;
  }

  .input-pet-details-2 {
    width: 200px;
  }

  .input-pet-details-dropdown {
    width: 200px;
  }

  .submit-login {
    width: 200px !important;
  }
}

@media only screen and (max-width: 330px) {
  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  .leftside-navigation {
    text-align: left !important;
  }
  .petparentlinks {
    text-align: left !important;
    margin-left: -70px !important;
  }
}

@media only screen and (max-width: 300px) {
  .input-pet-details {
    width: 170px;
  }

  .input-pet-details-2 {
    width: 170px;
  }

  .input-pet-details-dropdown {
    width: 170px;
  }
}

@media screen and (max-width: 607px) and (min-width: 576px) {
  .background {
    padding: 45px 0px 20px 20px !important;
  }
}

@media screen and (max-width: 480px) {
  .submit-login,
  .cancel-btn {
    width: 100% !important;
    margin: 10px !important;
    height: 100% !important;
  }
}

@media screen and (min-width: 48em) {
  .pet-activation-container {
    padding: 0 7em;
  }
}

@media screen and (min-width: 62.5em) {
  .petActivation-sidebar-toggle-btn-wrapper {
    display: none;
  }

  .petActivation-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }
}

@media screen and (min-width: 75em) {
  .petActivation-right-wrapper {
    margin-left: 17em;
  }

  .myform {
    padding: 0 10%;
  }
}

@media screen and (min-width: 78.125em) {
  .petActivation-sidebar-menu-link {
    font-size: 1rem;
  }

  .petActivation-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }

  .myform {
    padding: 0 20%;
  }

  .pet-activation-container {
    margin: 2% 0;
  }
}
